.fieldset {
  border: var(--border-width) solid var(--color-front);
  border-radius: 1px;
  position: relative;
  display: flex;

  justify-content: center;
  .legend {
    position: absolute;
    font-weight: 600;
    min-height: 30px;
    text-transform: uppercase;
    top: -20px;
    z-index: 2;
    padding: 5px;
    color: var(--color-front);
    background: var(--color-back);
    border: var(--border-width) solid var(--color-front);
  }
}
