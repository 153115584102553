.brand-tag { 
  position: absolute;
  padding: 5px;
  border: var(--border-width) solid var(--color-front);
  background-color: var(--color-back);
  z-index: 0;
}

.topbar {
  height: 50px;
  border-bottom: 2px solid var(--color-front);
}
