.card {
  padding: 0.7rem;

  border: var(--border-width) solid var(--color-front);
  background-color: var(--color-back);
  position: relative;
  &-shadowed {
    -webkit-box-shadow: 0.3rem 0.3rem 0px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0.3rem 0.3rem 0px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0.3rem 0.3rem 0px 0px rgba(0, 0, 0, 0.6);
  }

  &-special {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    border: var(--border-width) solid var(--color-front);
    margin: 0 10px 10px 0;
    &::before {
      content: "";

      position: absolute;
      z-index: -1;
      background-color: var(--color-back);

      height: 100%;
      width: 100%;
      top: 0.55rem;
      left: 0.55rem;
      border: var(--border-width) solid var(--color-front);
    }
    &::after {
      position: absolute;
      z-index: -2;
      background-color: var(--color-back);

      height: 100%;
      width: 100%;
      content: "";
      top: 1rem;
      left: 1rem;
      border: var(--border-width) solid var(--color-front);
    }
  }
}
